<template>
  <div>
    <c-tab
      type="horizon"
      :inlineLabel="true"
      :dense="true"
      :height="tabHeight"
      :tabItems="tabItems"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam="popupParam"
          :height="tabHeight"
          @closePopup="closePopup"
          @changeStatus="changeStatus"
          @changeComplete="changeComplete"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
export default {
  name: 'dangerRegularCheckTab',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        chmDangerRegularCheckId: '',
        chmDangerMstTypeId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      tab: 'dangerRegularCheckDetail', //처음 화면에 보여질 tab을 표시하는 부분
      tabItems: [
        { name: 'dangerRegularCheckDetail', icon: 'construction', label: '위험물 저장소 기본정보', component: () => import(`${'./dangerRegularCheckDetail.vue'}`) },
      ],
      addTabItems: [
        { name: 'dangerRegularCheckInfo', icon: 'edit', label: '위험물 저장소 정보', component: () => import(`${'./dangerRegularCheckInfo.vue'}`) }
      ],
    };
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 60);
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      if (this.popupParam.chmDangerRegularCheckId) {
        this.tabItems = this.tabItems.concat(this.addTabItems);
      }
      // list setting  
    },
    closePopup(data) {
      this.$emit('closePopup', data);  
    },
    changeStatus() {  //상태변화(등록했을 경우 개선관리 탭을 추가해서 보여줌)
      this.tabItems = this.tabItems.concat(this.addTabItems);
    },
    changeComplete() {
      this.popupParam.chmStatusDangerRegularCheckCd = 'CSDRCC0002';
    }
  }
};
</script>